import React, { useEffect, useState } from 'react';
import './Home.css'; // 引入CSS样式文件
import Navbar from './Navbar'; // 引入导航栏组件

const Home = () => {
  const [backgroundImage, setBackgroundImage] = useState(null);

  // 懒加载背景图像
  useEffect(() => {
    const loadImage = () => {
      setBackgroundImage('/background.webp'); // 在页面需要时加载图片
    };

    // 延迟加载背景图
    const handleScroll = () => {
      const bgElement = document.querySelector('.home');
      const rect = bgElement.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        loadImage();
      }
    };

    // 监听滚动事件
    window.addEventListener('scroll', handleScroll);

    // 初始时检查是否可以加载背景图
    handleScroll();

    return () => {
      // 清理事件监听
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className="home"
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : '', // 只有在加载时才应用背景图
        backgroundSize: 'cover', // 背景图自适应容器
        backgroundPosition: 'center', // 背景图居中
      }}
    >
      {/* 引入单独的导航栏组件 */}
      <Navbar />

      {/* 主标题 */}
      <header className="main-header">
        <h1>
          <span className="typing">b</span>
          <span className="typing">e</span>
          <span className="typing">n</span>
          <span className="typing">z</span>
          <span className="typing">u</span>
          <span className="typing">n</span>
          <span className="typing">的</span>
          <span className="typing">小</span>
          <span className="typing">窝</span>
        </h1>
        <p>欢迎来到我的小天地，探索我的创意与梦想！</p>
      </header>

      {/* 页面内容 */}
      <section className="content">
        <button className="cta-button">点击我了解更多</button>
      </section>
    </div>
  );
};

export default Home;
