import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './RoomPage.css';  // 引入自定义的 CSS 文件

const RoomPage = () => {
  const [room, setRoom] = useState(null);  // 存储房间数据
  const [password, setPassword] = useState('');  // 存储密码
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(true);  // 判断密码是否正确
  const { roomId } = useParams();  // 获取房间 ID
  const navigate = useNavigate();  // 导航函数

  // 获取房间信息
  useEffect(() => {
    if (!roomId) {
      console.error('房间 ID 不存在');
      return;
    }

    const fetchRoom = async () => {
      try {
        const response = await axios.get(`https://api.otakuhub.top/api/room/${roomId}`);
        setRoom(response.data);
      } catch (error) {
        console.error('获取房间信息失败:', error);
        navigate('/');  // 跳转到首页
      }
    };

    fetchRoom();
  }, [roomId, navigate]);

  // 处理加入房间
  const handleJoinRoom = async () => {
    if (room.password && password !== room.password) {
      setIsPasswordCorrect(false);
      return;
    }

    try {
      await axios.post('https://api.otakuhub.top/api/room/join', {
        roomId: roomId,
        user: window.location.hostname,  // 使用当前的 IP 地址或其他标识符
      });

      navigate(`/room/${roomId}`);  // 加入房间成功，跳转到房间页面
    } catch (error) {
      console.error('加入房间失败:', error);
      alert('加入房间失败，请稍后再试');
    }
  };

  if (!room) return <p>加载中...</p>;

  return (
    <div className="spotify-room">
      <header className="room-header">
        <h1>{room.roomName}</h1>
        {room.password && <span className="lock-icon">🔒</span>}
      </header>

      <section className="room-info">
        <h2>房间信息</h2>
        <p>房间 ID: {room._id}</p>
        <p>创建时间: {new Date(room.createdAt).toLocaleString()}</p>
        <p>参与用户: {room.users.join(', ')}</p>
      </section>

      {room.password && (
        <section className="password-section">
          <h2>请输入密码</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="房间密码"
          />
          {!isPasswordCorrect && <p className="error-message">密码错误，请重试。</p>}
          <button onClick={handleJoinRoom}>加入房间</button>
        </section>
      )}

      {!room.password && (
        <section className="join-room-section">
          <button onClick={handleJoinRoom}>加入房间</button>
        </section>
      )}
    </div>
  );
};

export default RoomPage;
