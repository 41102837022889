// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Spotify 风格首页 */
.spotify-home {
  background: #1DB954;
  color: white;
  min-height: 100vh;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.room-list h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.rooms {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.room-card {
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  width: 250px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.room-card:hover {
  transform: scale(1.05);
}

.room-info h3 {
  font-size: 1.2em;
}

.lock-icon {
  color: #d32f2f;
  margin-left: 10px;
}

.create-room {
  background-color: #333;
  padding: 30px;
  border-radius: 8px;
  margin-top: 20px;
}

.create-room input {
  background-color: #444;
  border: none;
  padding: 10px;
  color: white;
  border-radius: 8px;
  margin: 10px 0;
  width: 100%;
}

.create-room button {
  background-color: #1DB954;
  border: none;
  padding: 15px;
  color: white;
  font-size: 1em;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
}

.create-room button:hover {
  background-color: #1ed760;
}
`, "",{"version":3,"sources":["webpack://./src/components/SpotifyStyleHome.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;EACE,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Spotify 风格首页 */\n.spotify-home {\n  background: #1DB954;\n  color: white;\n  min-height: 100vh;\n  padding: 20px;\n  font-family: 'Arial', sans-serif;\n}\n\n.header {\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.room-list h2 {\n  font-size: 1.5em;\n  margin-bottom: 10px;\n}\n\n.rooms {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n}\n\n.room-card {\n  background-color: #1e1e1e;\n  padding: 20px;\n  border-radius: 8px;\n  width: 250px;\n  cursor: pointer;\n  transition: transform 0.3s ease;\n}\n\n.room-card:hover {\n  transform: scale(1.05);\n}\n\n.room-info h3 {\n  font-size: 1.2em;\n}\n\n.lock-icon {\n  color: #d32f2f;\n  margin-left: 10px;\n}\n\n.create-room {\n  background-color: #333;\n  padding: 30px;\n  border-radius: 8px;\n  margin-top: 20px;\n}\n\n.create-room input {\n  background-color: #444;\n  border: none;\n  padding: 10px;\n  color: white;\n  border-radius: 8px;\n  margin: 10px 0;\n  width: 100%;\n}\n\n.create-room button {\n  background-color: #1DB954;\n  border: none;\n  padding: 15px;\n  color: white;\n  font-size: 1em;\n  cursor: pointer;\n  border-radius: 8px;\n  width: 100%;\n}\n\n.create-room button:hover {\n  background-color: #1ed760;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
