import React, { useState } from 'react';
import './About.css'; // 引入CSS样式文件

const About = () => {
  const [showBubble, setShowBubble] = useState(false); // 控制气泡框显示
  const [randomQuote, setRandomQuote] = useState(""); // 存储随机的吉言
  const [showModal, setShowModal] = useState(false); // 控制弹窗显示
  const [emailContent, setEmailContent] = useState(''); // 存储邮箱信息

  // 每日吉言数组
  const quotes = [
    "今日事，今日毕。",
    "机会只留给有准备的人。",
    "做你害怕的事，成功就会跟着来。",
    "相信自己，你能做到。",
    "努力不一定成功，但不努力一定会失败。",
    "梦想不是空想，是有目标的行动。",
    "今天的你，正在为明天的你铺路。",
    "失败是成功之母。",
    "每一天都是新的开始。",
    "生活不止眼前的苟且，还有诗和远方。",
  ];

  // 随机选择吉言
  const getRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  };

  const handleImageHover = () => {
    setShowBubble(true);
    setRandomQuote(getRandomQuote());
  };

  const handleImageLeave = () => {
    // 1秒钟后让气泡框消失
    setTimeout(() => {
      setShowBubble(false);
    }, 1000);
  };

  // 弹出邮箱信息窗口
  const handleEmailClick = () => {
    setEmailContent('欢迎通过邮箱与我联系！邮箱：wuyikun2005@gmail.com'); // 设置邮箱相关信息
    setShowModal(true); // 显示弹窗
  };

  // 关闭弹窗
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="about">
      {/* 页面标题 */}
      <header className="about-header">
        <h1>关于我</h1>
        <p>你好，我是 Benzun，一个充满创意和激情的开发者！</p>
      </header>

      {/* 个人信息和照片 */}
      <section className="about-content">
        <div className="about-photo">
          <img
            src="/images/benzun.png"
            alt="我的照片"
            className="profile-img"
            onMouseEnter={handleImageHover} // 鼠标悬停时触发
            onMouseLeave={handleImageLeave} // 鼠标移开时触发
          />
          {showBubble && (
            <div className="bubble">
              <p>{randomQuote}</p>
            </div>
          )}
        </div>
        <div className="about-details">
          <h2>个人简介</h2>
          <p>
            我是一名普通人，热衷于构建高质量的网页和应用程序。我喜欢在开发中探索新技术，也热爱与其他开发者合作并分享我的经验。
          </p>
        </div>
      </section>

      {/* 社交链接 (只保留邮箱) */}
      <section className="social-links">
        <h2>联系方式</h2>
        <ul>
          <li>
            <a href="mailto:wuyikun2005@gmail.com" onClick={handleEmailClick}>
              Email
            </a>
          </li>
        </ul>
      </section>

      {/* 邮箱信息弹窗 */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>邮箱信息</h2>
            <p>{emailContent}</p>
            <button className="close-modal" onClick={closeModal}>关闭</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
