import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Navbar from './components/Navbar';
import ChatGPT from './components/ChatGPT';
import SpotifyStyleHome from './components/SpotifyStyleHome'; // 引入新的 Spotify 风格主页组件
import RoomPage from './components/RoomPage'; // 引入房间页面

const App = () => {
  return (
    <>
      <Navbar /> {/* 导航栏始终显示 */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/chatgpt" element={<ChatGPT />} />
        <Route path="/spotify" element={<SpotifyStyleHome />} />
        <Route path="/room/:roomId" element={<RoomPage />} /> {/* 路由动态渲染房间页面 */}
      </Routes>
    </>
  );
};

export default App;
