import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ChatGPT.css';

const ChatGPT = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(true); // 控制弹窗显示

  const MAX_HISTORY = 10; // 最大上下文消息数
  const API_URL = process.env.REACT_APP_API_URL || 'https://api.otakuhub.top/api/chat'; // API 请求地址

  // 监听回车键发送消息
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // 防止换行
      handleSend();
    }
  };

  const handleSend = async () => {
    if (!input.trim()) return;

    setLoading(true);
    setError(null);

    const newMessage = { role: 'user', content: input };
    const updatedMessages = [...messages, newMessage];

    // 保持最多 10 条消息
    if (updatedMessages.length > MAX_HISTORY) {
      updatedMessages.shift(); // 删除最旧的消息
    }

    try {
      const res = await axios.post(API_URL, { messages: updatedMessages });

      const aiMessage = { role: 'assistant', content: res.data.response };
      setMessages([...updatedMessages, aiMessage]);
      setInput('');
    } catch (err) {
      console.error(err);
      setError('请求失败，请稍后再试。');
    } finally {
      setLoading(false);
    }
  };

  // 关闭免责声明弹窗
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="chatgpt-wrapper">
      {/* 显示免责声明弹窗 */}
      {isModalVisible && (
        <div className="modal-overlay">
          <div className="modal-container">
            <h2>免责声明与AI使用协议</h2>
            <div className="modal-content">
              <h3>免责声明：</h3>
              <p>
                使用 ChatGPT 及相关服务时，用户应知晓并同意以下条款：
                <br />
                1. ChatGPT 是一个基于人工智能的语言模型，其生成的内容仅供参考，不能作为任何专业建议或决策的依据。
                <br />
                2. 用户对 ChatGPT 提供的回答进行的任何行动，均由用户自行承担风险和责任。
                <br />
                3. 本服务不可用于非法、恶意或有害的行为，包括但不限于传播虚假信息、侵权、骚扰他人等。
                <br />
                4. 本协议内容可能会随时更新，用户需定期查看最新版本。
              </p>
            </div>
            <div className="modal-actions">
              <button onClick={handleCloseModal} className="modal-close-button">
                我同意
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ChatGPT 主要内容 */}
      <div className="chatgpt-container">
        <h1 className="chatgpt-title">ChatGPT</h1>
        <div className="chatgpt-messages">
          {messages.map((msg, idx) => (
            <div
              key={idx}
              className={`chatgpt-message ${msg.role === 'user' ? 'user-message' : 'ai-message'}`}
            >
              <span>{msg.content}</span>
            </div>
          ))}
        </div>
        <div className="chatgpt-input-container">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="输入消息..."
            disabled={loading}
            className="chatgpt-input"
            rows={3} // 允许输入多行
          />
          <button
            onClick={handleSend}
            disabled={!input.trim() || loading}
            className="chatgpt-button"
          >
            {loading ? '发送中...' : '发送'}
          </button>
        </div>
        {error && <div className="chatgpt-error">{error}</div>}
      </div>
    </div>
  );
};

export default ChatGPT;
