import React, { useState, useEffect } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 初始判断是否为手机端
  const [isOpen, setIsOpen] = useState(false); // 控制菜单的显示与隐藏

  useEffect(() => {
    // 窗口大小改变时更新设备类型
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    // 添加窗口大小监听事件
    window.addEventListener('resize', handleResize);

    // 清理事件监听器
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // 切换菜单显示
  const toggleMenu = () => setIsOpen((prev) => !prev);

  // 点击链接后关闭菜单（仅限手机端）
  const handleLinkClick = () => {
    if (isMobile) setIsOpen(false);
  };

  return (
    <nav className={`navbar ${isOpen ? 'open' : ''}`}>
      {/* 移动端菜单按钮 */}
      {isMobile && (
        <div className="hamburger" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}

      {/* 导航链接 */}
      <ul className={`navbar-links ${isMobile && isOpen ? 'open' : ''}`}>
        <li>
          <a href="/" onClick={handleLinkClick}>首页</a>
        </li>
        <li>
          <a href="/about" onClick={handleLinkClick}>关于我</a>
        </li>
        <li>
          <a href="/chatgpt" onClick={handleLinkClick}>ChatGPT</a>
        </li>
        <li>
          <a href="/spotify" onClick={handleLinkClick}>一起听音乐</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
