// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Spotify 风格房间页面 */
.spotify-room {
  background: #121212;
  color: white;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.room-header h1 {
  font-size: 2em;
}

.room-info p {
  font-size: 1em;
  margin: 5px 0;
}

.password-section,
.join-room-section {
  margin-top: 30px;
}

.password-section input {
  background-color: #444;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
  color: white;
}

.password-section button {
  background-color: #1DB954;
  color: white;
  padding: 15px;
  width: 100%;
  border: none;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
}

.password-section button:hover {
  background-color: #1ed760;
}

.error-message {
  color: red;
  font-size: 0.9em;
}
`, "",{"version":3,"sources":["webpack://./src/components/RoomPage.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,gCAAgC;EAChC,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,aAAa;AACf;;AAEA;;EAEE,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB","sourcesContent":["/* Spotify 风格房间页面 */\n.spotify-room {\n  background: #121212;\n  color: white;\n  min-height: 100vh;\n  font-family: 'Arial', sans-serif;\n  padding: 20px;\n}\n\n.room-header h1 {\n  font-size: 2em;\n}\n\n.room-info p {\n  font-size: 1em;\n  margin: 5px 0;\n}\n\n.password-section,\n.join-room-section {\n  margin-top: 30px;\n}\n\n.password-section input {\n  background-color: #444;\n  padding: 10px;\n  border-radius: 8px;\n  width: 100%;\n  color: white;\n}\n\n.password-section button {\n  background-color: #1DB954;\n  color: white;\n  padding: 15px;\n  width: 100%;\n  border: none;\n  border-radius: 8px;\n  margin-top: 20px;\n  cursor: pointer;\n}\n\n.password-section button:hover {\n  background-color: #1ed760;\n}\n\n.error-message {\n  color: red;\n  font-size: 0.9em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
