import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './SpotifyStyleHome.css';  // 引入自定义的 CSS 文件

const SpotifyStyleHome = () => {
  const [rooms, setRooms] = useState([]);  // 存储房间列表
  const [roomName, setRoomName] = useState('');  // 存储房间名称
  const [roomPassword, setRoomPassword] = useState('');  // 存储房间密码
  const navigate = useNavigate();  // 导航函数

  // 获取所有房间信息
  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get('https://api.otakuhub.top/api/room');
        setRooms(response.data);  // 设置房间列表
      } catch (error) {
        console.error('获取房间信息失败:', error);
      }
    };

    fetchRooms();
  }, []);

  // 创建房间
  const handleCreateRoom = async () => {
    if (roomName.trim() === '') {
      alert('请输入房间名称');
      return;
    }

    try {
      const response = await axios.post('https://api.otakuhub.top/api/room', {
        roomName: roomName,
        password: roomPassword,  // 如果有密码，传递密码
        users: [''],  // 用户信息可以在后端处理
      });

      if (response.status === 201) {
        navigate(`/room/${response.data.roomId}`);  // 跳转到创建的房间
      } else {
        alert('创建房间失败，请稍后再试');
      }
    } catch (error) {
      console.error('创建房间失败:', error);
      alert('创建房间失败，请稍后再试');
    }
  };

  return (
    <div className="spotify-home">
      <header className="header">
        <h1>选择一个房间</h1>
        <p>选择你要加入的房间，或者创建新的房间。</p>
      </header>

      <section className="room-list">
        <h2>已创建的房间</h2>
        <div className="rooms">
          {rooms.length === 0 ? (
            <p>没有房间可用。</p>
          ) : (
            rooms.map((room) => (
              <div
                key={room._id}
                className={`room-card ${room.password ? 'locked' : 'open'}`}
                onClick={() => navigate(`/room/${room._id}`)}
              >
                <div className="room-info">
                  <h3>{room.roomName}</h3>
                  {room.password && <span className="lock-icon">🔒</span>}
                </div>
              </div>
            ))
          )}
        </div>
      </section>

      <section className="create-room">
        <h2>创建新房间</h2>
        <input
          type="text"
          placeholder="房间名称"
          value={roomName}
          onChange={(e) => setRoomName(e.target.value)}
        />
        <input
          type="password"
          placeholder="密码 (可选)"
          value={roomPassword}
          onChange={(e) => setRoomPassword(e.target.value)}
        />
        <button onClick={handleCreateRoom}>创建房间</button>
      </section>
    </div>
  );
};

export default SpotifyStyleHome;
